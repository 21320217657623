import { makeStyles } from '@material-ui/styles'
import Text from 'core/elements/Text'
import SelectableCard from 'pf9-ui-components/built/components/SelectableCard'
import Radio from 'pf9-ui-components/built/elements/input/Radio'
import Theme from 'pf9-ui-components/built/theme-manager/themes/model'
import React from 'react'

export default function ControlPlaneCard({ id, onClick, active, title, description }) {
  const classes = useStyles()
  return (
    <SelectableCard id={id} onClick={onClick} active={active} className={classes.controlPlaneCard}>
      <div className={classes.cardBody}>
        <Radio checked={active} />
        <div>
          <Text variant="caption1">{title}</Text>
          <Text variant="body2">{description}</Text>
        </div>
      </div>
    </SelectableCard>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  controlPlaneCard: {
    width: '346px',
    height: '80px',
  },
  cardBody: {
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr',
    gridGap: theme.spacing(2),
  },
}))
