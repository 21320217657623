import { makeStyles } from '@material-ui/styles'
import Text from 'core/elements/Text'
import Theme from 'core/themes/model'
import DropdownField from 'pf9-ui-components/built/components/validatedForm/DropdownField'
import AsyncDropdown from 'pf9-ui-components/built/elements/dropdown/AsyncDropdown'
import React from 'react'
import ControlPlaneCard from './ControlPlaneCard'
import VirtualizedClustersTable from './VirtualizedClustersTable'

const controlPlaneClassOptions = [
  { label: 'Highly available - 3 masters (default)', value: 'high-availability' },
]

export default function ClusterArchitectureFields({
  virtualizedClusters,
  params,
  getParamsUpdater,
}) {
  const classes = useStyles()

  return (
    <div className={classes.clusterArchitectureFields}>
      <Text variant="body2">
        Select the compute infrastructure for this cluster. Using Virtualized Clusters enables
        integrated management of node pools, cluster autoscaler, storage and load balancers.
      </Text>
      <VirtualizedClustersTable
        data={virtualizedClusters}
        selectedClusterName={params.cluster}
        onClusterSelection={getParamsUpdater('cluster')}
      />
      <div className={classes.controlPlaneOptions}>
        <Text variant="body2">Select the type of control plane.</Text>
        <div className={classes.controlPlaneCards}>
          <ControlPlaneCard
            id="managed"
            onClick={getParamsUpdater('controlPlaneType')}
            active={params.controlPlaneType === 'managed'}
            title="Managed Control Plane"
            description="On PCD"
          />
          <ControlPlaneCard
            id="custom"
            onClick={getParamsUpdater('controlPlaneType')}
            active={params.controlPlaneType === 'custom'}
            title="Custom Control Plane"
            description="Advanced"
          />
        </div>
      </div>

      <DropdownField
        id="controlPlaneClass"
        label="Control Plane Class"
        DropdownComponent={AsyncDropdown}
        items={controlPlaneClassOptions}
        value={params.alertMethod}
        onChange={getParamsUpdater('controlPlaneClass')}
        disabled={params.controlPlaneType === 'managed'}
        selectFirst
      />
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  clusterArchitectureFields: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  controlPlaneOptions: {
    display: 'grid',
    margin: theme.spacing(2, 0),
    gridGap: theme.spacing(2),
  },
  controlPlaneCards: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    gridGap: theme.spacing(2),
  },
}))
