import useScopedPreferences from 'core/session/useScopedPreferences'
import { routes } from 'core/utils/routes'

import React, { useEffect } from 'react'
import useReactRouter from 'use-react-router'

interface Option {
  label: string
  value: string
}

const loading = false
const clusters = [
  { uuid: 1, name: 'cluster-name-1' },
  { uuid: 2, name: 'cluster-name-2' },
  { uuid: 3, name: 'cluster-name-3' },
]

export default function HomePage() {
  const { prefs, updatePrefs } = useScopedPreferences('k8sPluginGlobalParams')
  const { history } = useReactRouter()

  // TODO: Get clusters list
  // const { loading } = useListAction(listClusters)
  // const clusters = useSelector(clustersSelector)

  // If a cluster is not already specified in the URL, choose the first cluster in the list
  useEffect(() => {
    if (loading || clusters.length === 0) return
    const cluster = prefs?.cluster || clusters[0].name
    console.log('hi')

    history.push(routes.manage.overview.path({ cluster: cluster as string }))
  }, [clusters])

  return <></>
}
