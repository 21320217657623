import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { projectAs } from 'app/utils/fp'
import clsx from 'clsx'
import { Route } from 'core/plugins/route'
import useScopedPreferences from 'core/session/useScopedPreferences'
import AsyncDropdown from 'pf9-ui-components/built/elements/dropdown/AsyncDropdown'
import { head, isEmpty, prop } from 'ramda'
import React, { useEffect, useMemo } from 'react'
import useReactRouter from 'use-react-router'

interface Option {
  label: string
  value: string
}

const isValidOption = (selectedCluster, options) =>
  !!selectedCluster && !!options.find((c) => c.value === selectedCluster)

export const getClusterNameFromUrl = () => {
  const matches = location.pathname.match(/\/cluster\/([^/]+)/)
  return matches?.[1]
}

export default function GlobalClusterDropdown({ className }) {
  const classes = useStyles()
  const { prefs, updatePrefs } = useScopedPreferences('k8sPluginGlobalParams')
  const { history, location } = useReactRouter()

  // TODO: Get clusters list
  // const { loading } = useListAction(listClusters)
  // const clusters = useSelector(clustersSelector)
  const clusters = [
    { uuid: 1, name: 'cluster-name-1' },
    { uuid: 2, name: 'cluster-name-2' },
    { uuid: 3, name: 'cluster-name-3' },
  ]

  const options = useMemo(() => {
    return [...projectAs({ label: 'name', value: 'name' }, clusters)]
  }, [clusters])

  // On the first render, if there is a cluster alerady specified in the URL, store
  // that as the global cluster name. For example, if the user refreshes the page
  // or opens up a new tab/window using the same url, we want to use the cluster in the url
  useEffect(() => {
    const route = Route.getCurrentRoute(location.pathname)
    if (!route?.url || !route.url.includes(':cluster')) return
    const cluster = getClusterNameFromUrl()
    if (cluster) {
      updatePrefs({ cluster })
    }
  }, [])

  // If a cluster is not already specified in the URL, choose the first cluster in the list
  useEffect(() => {
    if (isEmpty(options) || isValidOption(prefs.cluster, options)) return
    const firstCluster = prop<'value', Option>('value', head(options))
    updatePrefs({ cluster: firstCluster })
    updateUrl(firstCluster)
  }, [prefs.cluster, options])

  // Update the cluster in the URL whenever the global cluster  name has changed
  useEffect(() => {
    if (!prefs.cluster) return
    updateUrl(prefs.cluster)
  }, [prefs.cluster])

  const updateUrl = (cluster) => {
    const route = Route.getCurrentRoute(location.pathname)
    if (!route?.url || !route.url.includes(':cluster')) return
    history.push(route.path({ cluster }))
  }

  return (
    <AsyncDropdown
      className={clsx(classes.dropdown, className)}
      label="Cluster"
      items={options}
      value={prefs.cluster}
      onChange={(cluster) => updatePrefs({ cluster })}
      compact={false}
      icon="server"
      disabled={clusters.length === 0}
    />
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  dropdown: {},
}))
