/* eslint-disable no-extra-boolean-cast */
import React from 'react'
import useDataLoader from 'core/hooks/useDataLoader'
import { loadServiceCatalog } from 'k8s/components/apiAccess/endpoints/actions'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import DocumentMeta from 'core/components/DocumentMeta'
import Grid from 'core/elements/grid'
import CodeBlock from 'core/components/CodeBlock'
import { useSelector } from 'react-redux'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { RootState } from 'app/store'
import { prop } from 'ramda'
import useScopedPreferences from 'core/session/useScopedPreferences'
import Cookies from 'js-cookie'
import { DOMAIN_NAME } from 'app/constants'

const columns = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'url',
    label: 'URL',
  },
]

export default function ApiServicesPage() {
  const classes = useStyles()

  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const {
    userDetails: { name },
    activeRegion,
    domain,
  } = session
  const { prefs, updatePrefs } = useScopedPreferences()
  const { currentTenantName, currentRegion } = prefs
  const fqdn = window.location.host
  const currentDomain = domain?.name || Cookies.get(DOMAIN_NAME) || 'default'

  const [serviceCatalog] = useDataLoader(loadServiceCatalog)

  const services = serviceCatalog.filter((service) => service.name !== 'resmgr')

  return (
    <>
      <DocumentMeta title="API Services" breadcrumbs />
      <div className={classes.sections}>
        <div className={classes.section}>
          <Text variant="subtitle1">API Endpoints</Text>
          <Grid
            uniqueIdentifier="id"
            data={services}
            columns={columns}
            loading={false}
            disableToolbar
          />
        </div>
        <div className={classes.section}>
          <Text variant="subtitle1">OpenStack RC</Text>
          <Text variant="body2">
            These environment variables are needed to use the OpenStack command-line interfaces.
          </Text>
          <CodeBlock>
            {`export OS_USERNAME=${name}
export OS_PASSWORD=<your-password>
export OS_AUTH_URL=https://${fqdn}/keystone/v3
export OS_AUTH_TYPE=password
export OS_IDENTITY_API_VERSION=3
export OS_REGION_NAME=${currentRegion || activeRegion}
export OS_USER_DOMAIN_NAME=${currentDomain}
export OS_PROJECT_DOMAIN_NAME=${currentDomain}
export OS_PROJECT_NAME=${currentTenantName}
export OS_INTERFACE=public`}
          </CodeBlock>
        </div>
      </div>
    </>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  sections: {
    display: 'grid',
    gap: 32,
  },
  section: {
    display: 'grid',
    gap: 16,
  },
}))
