import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import useParams from 'core/hooks/useParams'
import FormFieldSection from 'pf9-ui-components/built/components/validatedForm/FormFieldSection'
import TextField from 'pf9-ui-components/built/components/validatedForm/TextField'
import ModalForm from 'pf9-ui-components/built/elements/modal/ModalForm'
import Theme from 'pf9-ui-components/built/theme-manager/themes/model'
import React from 'react'
import ClusterArchitectureFields from './form-components/ClusterArchitectureFields'
import ClusterConfigFields from './form-components/ClusterConfigFields'
import ServerConfigurationFields from './form-components/ServerConfigurationFields'

export const virtualizedClustersMockData = [
  {
    name: 'Default',
    vcpus: { current: 386, total: 520 },
    memory: { current: 387, total: 2048 },
    storage: { current: 131, total: 400 },
  },
]

const vmFlavors = [
  { id: 1, name: 'Lowcore-Small', vcpus: 2, memory: 8, storage: 40 },
  { id: 2, name: 'Lowcore-Medium', vcpus: 4, memory: 16, storage: 40 },
  { id: 3, name: 'Lowcore-Medium', vcpus: 8, memory: 32, storage: 40 },
  { id: 4, name: 'Highcore-Large', vcpus: 16, memory: 60, storage: 80 },
]

const initialValues = {
  useExistingVirtualizedCluster: true,
  nodePools: [{ id: 1 }],
  controlPlaneType: 'managed',
  cluster: 'Default',
}

const DeployCloudspaceModalForm = (props) => {
  const { params, getParamsUpdater } = useParams<Record<string, unknown>>(initialValues)
  const classes = useStyles()

  const handleSubmit = () => {
    console.log('Submitting form', params)
  }

  return (
    <ModalForm
      {...props}
      open={true}
      title="Deploy A New Cluster"
      maxWidth={820}
      className={classes.modal}
      onSubmit={handleSubmit}
      submitTitle="Deploy Cluster"
    >
      {/* Step 1 */}
      <FormFieldSection title="Cluster Architecture" step={1}>
        <div className={classes.formSection}>
          <ClusterArchitectureFields
            virtualizedClusters={virtualizedClustersMockData}
            params={params}
            getParamsUpdater={getParamsUpdater}
          />
        </div>
      </FormFieldSection>

      {/* Step 2 */}
      <FormFieldSection title="Servers, Storage, and Network" step={2}>
        <div className={classes.formSection}>
          <ServerConfigurationFields
            id="nodePools"
            vmFlavors={vmFlavors}
            params={params}
            getParamsUpdater={getParamsUpdater}
          />
        </div>
      </FormFieldSection>

      {/* Step 3 */}
      <FormFieldSection title="Cluster Infrastructure" step={3}>
        <div className={classes.formSection}>
          <ClusterConfigFields params={params} getParamsUpdater={getParamsUpdater} />
        </div>
      </FormFieldSection>

      {/* Step 4 */}
      <FormFieldSection title="Final Touches" step={4}>
        <div className={clsx(classes.formSection, classes.finalTouchesSection)}>
          <TextField
            id="name"
            label="Cluster Name"
            value={params.name}
            onChange={getParamsUpdater('name')}
            required
            nonce={undefined}
            onResize={undefined}
            onResizeCapture={undefined}
            enterKeyHint={undefined}
          />
        </div>
      </FormFieldSection>
    </ModalForm>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  modal: {
    '& .modal-body': {
      padding: '32px 24px 24px 32px',
    },
    '& .modal-body .progress-root .progressContent > form': {
      marginBottom: 0,
    },
  },
  formSection: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: theme.spacing(2),
    marginLeft: '12px',
    marginTop: theme.spacing(2),
  },
  finalTouchesSection: {
    marginTop: theme.spacing(2),
  },
}))

export default DeployCloudspaceModalForm
