import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import createContextLoader from 'core/helpers/createContextLoader'
import createCRUDActions from 'core/helpers/createCRUDActions'
import { ActionDataKeys } from 'k8s/DataKeys'
import { emptyObj } from 'utils/fp'

const serviceMappings = {
  aodh: 'internal',
  azmanager: 'admin',
  ceilometer: 'internal',
  cinder: 'internal',
  credsmgr: 'admin',
  glance: 'admin',
  gnocchi: 'internal',
  ironic: 'public',
  mors: 'internal',
  murano: 'internal',
  neutron: 'internal',
  nova: 'internal',
  panko: 'internal',
  qbert: 'internal',
  resmgr: 'internal',
  tasker: 'admin',
}

const { keystone } = ApiClient.getInstance()

// const whichInterface = (serviceName) => serviceMappings[serviceName] || 'internal'
const whichInterface = (serviceName) => 'public'

export const loadServiceCatalog = createContextLoader(ActionDataKeys.ServiceCatalog, async () => {
  const services = await keystone.getServicesForActiveRegion()
  return Object.entries(services || emptyObj).map(([name, service]) => {
    const iface = whichInterface(service)
    const endpoint = (service && service[iface]) || service[Object.keys(service)[0]]
    return {
      id: endpoint.id,
      name,
      type: endpoint.type,
      url: endpoint.url,
      iface: endpoint.iface,
    }
  })
})

const endpointsActions = createCRUDActions(ActionDataKeys.ApiEndpoints, {
  // TODO: implement list fetching real data
  listFn: async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get API endpoints')
    const whitelist = ['qbert', 'keystone']
    const services = await loadServiceCatalog()
    return services.filter((service) => whitelist.includes(service.name))
  },
})

export default endpointsActions
