import { makeStyles } from '@material-ui/styles'
import QuantitySelector from 'pf9-ui-components/built/components/QuantitySelector'
import Grid, { GridViewColumn } from 'pf9-ui-components/built/elements/grid/Grid'
import Theme from 'pf9-ui-components/built/theme-manager/themes/model'
import React, { useEffect, useState } from 'react'

const VmExpandedRow = ({ quantity = 1, onChange }) => {
  const classes = useVmExpandedRowStyles()
  return (
    <div className={classes.expandedRowContainer}>
      <QuantitySelector
        id="quantity"
        onChange={onChange}
        value={quantity}
        className={classes.quantitySelector}
      />
    </div>
  )
}

const useVmExpandedRowStyles = makeStyles((theme: Theme) => ({
  expandedRowContainer: {
    display: 'grid',
    padding: theme.spacing(2),
  },
  quantitySelector: {
    marginLeft: '42px',
  },
}))

interface VirtualMachines {
  id: number
  name: string
  vcpus: { current: number; total: number }
  memory: { current: number; total: number }
  storage: { current: number; total: number }
}

const columns: GridViewColumn<VirtualMachines>[] = [
  { key: 'name', label: 'Virtualized Cluster' },
  { key: 'vcpus', label: 'vCPUs Available' },
  { key: 'memory', label: 'Memory (Gb) Available' },
  { key: 'storage', label: 'Storage (Gb) Available' },
]

export default function VirtualMachinesTable({
  data,
  selectedVmFlavor,
  selectedVmQuantity,
  onChange,
}) {
  const [selectedVms, setSelectedVms] = useState([])

  const handleVmSelection = (selectedItems) => {
    setSelectedVms(selectedItems)
    onChange('vmFlavor', selectedItems[0]?.name)
  }

  const handleVmFlavorChange = (quantity) => {
    onChange('quantity', quantity)
  }

  useEffect(() => {
    if (selectedVms[0]?.name === selectedVmFlavor) return
    setSelectedVms(data.filter((cluster) => cluster.name === selectedVmFlavor))
  }, [selectedVmFlavor, selectedVms[0]?.name, data])

  return (
    <Grid
      uniqueIdentifier="id"
      columns={columns}
      data={data}
      orderBy="vcpus"
      orderDirection="asc"
      emptyContent="No virtual machines available"
      disableRowSelection={false}
      multiSelection={false}
      selectedItems={selectedVms}
      onSelectChange={handleVmSelection}
      expandableRow={() => {
        return <VmExpandedRow quantity={selectedVmQuantity} onChange={handleVmFlavorChange} />
      }}
      expandedByDefault={(vm) => vm.name === selectedVmFlavor}
      expandRowsUponSelection
      disableToolbar
      hidePagination
    />
  )
}
