import { makeStyles } from '@material-ui/styles'
import Text from 'core/elements/Text'
import ProgressBar from 'pf9-ui-components/built/components/progress/ProgressBar'
import Grid, { GridViewColumn } from 'pf9-ui-components/built/elements/grid/Grid'
import Theme from 'pf9-ui-components/built/theme-manager/themes/model'
import React, { useEffect, useState } from 'react'

interface VirtualizedCluster {
  name: string
  vcpus: { current: number; total: number }
  memory: { current: number; total: number }
  storage: { current: number; total: number }
}

const getCapacityBarColor = (percent) => {
  if (percent < 40) return '#3BA489'
  if (percent < 70) return '#FFD7A2'
  return '#F2696A'
}

const CapacityBar = ({ value }) => {
  if (!value || value.current === undefined || value.total === undefined) return null
  const percent = (value.current / value.total) * 100
  const label = `${value.current} / ${value.total}`
  return (
    <div>
      <Text variant="body2">{label}</Text>
      <ProgressBar percent={percent} showPercent={false} color={getCapacityBarColor(percent)} />
    </div>
  )
}

const columns: GridViewColumn<VirtualizedCluster>[] = [
  { key: 'name', label: 'PCD Virtualized Cluster' },
  { key: 'vcpus', label: 'vCPUs Available', CellComponent: CapacityBar },
  { key: 'memory', label: 'Memory (Gb) Available', CellComponent: CapacityBar },
  { key: 'storage', label: 'Storage (Gb) Available', CellComponent: CapacityBar },
]

export default function VirtualizedClustersTable({
  data,
  selectedClusterName,
  onClusterSelection,
  required = true,
}) {
  const classes = useStyles()
  const [selectedClusters, setSelectedClusters] = useState([])

  const handleClusterSelection = (selectedItems) => {
    const hasPreviousSelection = selectedClusters.length > 0
    // Reselect the previous selection if the user tries to deselect a required field
    if (selectedItems.length === 0 && required && hasPreviousSelection) {
      setSelectedClusters([...selectedClusters])
    } else {
      setSelectedClusters(selectedItems)
      onClusterSelection(selectedItems[0]?.name)
    }
  }

  useEffect(() => {
    if (selectedClusters[0]?.name === selectedClusterName) return
    setSelectedClusters(data.filter((cluster) => cluster.name === selectedClusterName))
  }, [selectedClusterName, selectedClusters[0]?.name, data])

  return (
    <div className={classes.table}>
      <Grid
        uniqueIdentifier="name"
        columns={columns}
        data={data}
        orderBy="vcpus"
        orderDirection="asc"
        emptyContent="No virtualized clusters available"
        disableRowSelection={false}
        multiSelection={false}
        selectedItems={selectedClusters}
        onSelectChange={handleClusterSelection}
        disableToolbar
        hidePagination
      />
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  table: {
    '& .progress-root': {
      minHeight: 'max-content',
    },
  },
}))
