/* eslint-disable max-len */
import { pf9DocOrigin } from 'app/constants'

// Documentation links
const k8sBaseUrl = `${pf9DocOrigin}/kubernetes`
const microsoftDocBaseUrl = 'https://docs.microsoft.com/en-us'
const amazonDocBaseUrl = 'http://docs.aws.amazon.com'
const pf9GitHubRepoUrl = 'https://raw.githubusercontent.com/platform9'
export const pf9SupportBaseUrl = 'https://support.platform9.com'
const communityBaseUrl = 'https://community.platform9.com'
const k8sDocBaselUrl = 'https://kubernetes.io/docs'
const dockerDocBaseUrl = 'https://docs.docker.com'
export const pf9LearnBaseUrl = 'https://platform9.com/learn'
export const runtimeMigrationArticleLink =
  'https://platform9.com/docs/kubernetes/migrate-container-runtime-from-docker-to-containerd'

// Qbert
export const qbertApiLink = `${pf9DocOrigin}/qbert/ref`

// Profiles
export const profilesHelpUrl = `${k8sBaseUrl}/profile-engine-overview`

// MFA
export const MFAHelpLink = `https://platform9.com/docs/private-cloud-director/private-cloud-director/login-with-multi-factor-authentication`

// Help
export const gettingStartedHelpLink = `${k8sBaseUrl}/getting-started`
export const slackLink = 'https://slack.platform9.io'
export const forumHelpLink = `${communityBaseUrl}/`
export const pf9PmkArchitectureDigLink = `${k8sBaseUrl}/multimaster-architecture-platform9-managed-kubernetes/`
export const requestFormLink = `${pf9SupportBaseUrl}/hc/en-us/requests/new?ticket_form_id=360000924873`

// Cloud Providers
export const pf9CPCLILink = `${k8sBaseUrl}/cloud-provider-iam-check`

// Aws
export const awsPrerequisitesLink = `${k8sBaseUrl}/aws-prerequisites`
export const awsNetworkingConfigurationsLink = `${k8sBaseUrl}/aws-network-integrations`
export const awsAccessHelpLink = `${amazonDocBaseUrl}/general/latest/gr/aws-sec-cred-types.html#access-keys-and-secret-access-keys`
export const iamPolicyLink = `${pf9GitHubRepoUrl}/support-locker/master/pmk/aws-policy.json`
export const awsRoute53HelpLink = `${amazonDocBaseUrl}/Route53/latest/DeveloperGuide/dns-configuring-new-domain.html`
export const eksHelpLink = `${k8sBaseUrl}/eks-cluster-management`

// Azure
export const azurePrerequisitesLink = `${k8sBaseUrl}/azure-prerequisites`
export const azureServicePrincipalPortalLink = `${microsoftDocBaseUrl}/azure/active-directory/develop/howto-create-service-principal-portal`
export const azureGetValuesForSigninginLink = `${microsoftDocBaseUrl}/azure/active-directory/develop/howto-create-service-principal-portal#get-values-for-signing-in`
export const azureCreateANewApplicationSecretLink = `${microsoftDocBaseUrl}/azure/active-directory/develop/howto-create-service-principal-portal#create-a-new-application-secret`

// Google
export const googlePrerequisitesLink = `${k8sBaseUrl}/google-cloud-prerequisites`

// BareOS
export const bareOSHelpLink = `${k8sBaseUrl}/bareos-what-is-bareos`
export const bareOSSetupDocumentationLink = `${k8sBaseUrl}/create-multi-master-cluster`
export const bareOSSingleMasterSetupDocsLink = `${k8sBaseUrl}/create-single-master-multi-node-cluster`
export const authorizeNodeHelpLink = `${k8sBaseUrl}/authorize-a-node`

// Clusters
export const remoteMonitoringDocsLink = `${k8sBaseUrl}/enable-remote-monitoring/`

// Nodes
export const nodeInstallTroubleshooting = `${k8sBaseUrl}/troubleshooting-node-install-status-check`
export const nodePrerequisitesDocumentationLink = `${k8sBaseUrl}/kubernetes-cluster-pre-requisites`
export const ovaDocumentationLink = `${k8sBaseUrl}/get-started-pmk-ova`
export const airCtlDocumentationLink = `${pf9DocOrigin}/PEC/installation#configure-hosts`

// CLI
export const pmkCliOverviewLink = `${k8sBaseUrl}/pmk-cli-overview`
export const pmkCliPrepNodeLink = `${k8sBaseUrl}/cli-prep-node`
export const pmkCliCommandsLink = `${k8sBaseUrl}/pmk-cli-commands`

// Pods
export const createPodLink = `${k8sDocBaselUrl}/tasks/configure-pod-container/communicate-containers-same-pod/#creating-a-pod-that-runs-two-containers`

// Deployments
export const createDeploymentLink = `${k8sDocBaselUrl}/concepts/workloads/controllers/deployment/#creating-a-deployment`

// Services
export const createServiceLink = `${k8sDocBaselUrl}/tutorials/connecting-apps/connecting-frontend-backend/#creating-the-backend-service-object`

// Storage
export const persistVolumesStorageClassesLink = `${k8sDocBaselUrl}/concepts/storage/persistent-volumes/#storageclasses`

// Api Access
export const kubeconfigFileLink = `${k8sBaseUrl}/kubeconfig-and-clients-introduction-to-kubeconfig`
export const kubectlOverviewLink = `${k8sDocBaselUrl}/user-guide/kubectl-overview/`
export const konformGithubLink = `${pf9GitHubRepoUrl}/konform`
export const terraformGitubLink = 'https://github.com/platform9/terraform-provider-pf9'
// Misc
export const runtimePrivilegedLink = `${dockerDocBaseUrl}/engine/reference/run/#runtime-privilege-and-linux-capabilities`
export const applicationLoadBalancer = `${k8sBaseUrl}/metallb-application-load-balancing`

// Helm
export const rookReleaseLink = 'https://charts.rook.io/release'
export const openEbsChartsLink = 'https://openebs.github.io/charts'
export const kubeCostChartLink = 'https://kubecost.github.io/cost-analyzer/'
export const traefikChartLink = 'https://helm.traefik.io/traefik'
export const tanzuChartsLink = 'https://vmware-tanzu.github.io/helm-charts'
export const pf9ChartsLink = 'https://platform9.github.io/helm-charts'
export const artifactsHubLink = 'https://artifacthub.io'

// CAPI
export const capiInfoLink = 'https://cluster-api.sigs.k8s.io/introduction.html'

// Networks
export const enableSriovLink = `${k8sBaseUrl}/how-to-luigi---sriov---kernel-driver`
export const enableSriovWithDpdkLink = `${k8sBaseUrl}/how-to-luigi---sriov---dpdk-driver`
export const enableSriovLinkWithConfigMapAnchor = `${k8sBaseUrl}/how-to-luigi---sriov---kernel-driver#create-sr-iov-configmap`
export const enableSriovWithDpdkLinkWithConfigMapAnchor = `${k8sBaseUrl}/how-to-luigi---sriov---dpdk-driver#create-sr-iov-configmap`
export const enableOvsWithDpdkLink = `${k8sBaseUrl}/how-to-luigi---ovs---dpdk-driver`
export const enableDhcpLink = `${k8sBaseUrl}/enable-p9-dhcp`
