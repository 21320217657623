import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import Text from 'core/elements/Text'
import useToggler from 'core/hooks/useToggler'
import Accordion from 'pf9-ui-components/built/components/accordion/Accordion'
import QuantitySelector from 'pf9-ui-components/built/components/QuantitySelector'
import DropdownField from 'pf9-ui-components/built/components/validatedForm/DropdownField'
import ToggleSwitchField from 'pf9-ui-components/built/components/validatedForm/ToggleSwitchField'
import AsyncDropdown from 'pf9-ui-components/built/elements/dropdown/AsyncDropdown'
import Theme from 'pf9-ui-components/built/theme-manager/themes/model'
import React from 'react'
import VirtualMachinesTable from './VirtualMachinesTable'

const storageClassOptions = [
  { label: 'Tier1-NetApp (30 / 50 Gb available)', value: 'tier1-netapp' },
]

const networkOptions = [
  { label: 'vm-network (x.y.z.a / 24)', value: 'vm-network' },
  { label: 'vm-network (x.y.z.a / 24)', value: 'vm-network' },
]

interface NodePoolConfigCardProps {
  nodePool: any
  vmFlavors: any[]
  onChange: (fieldId: string, value: any) => void
}

export default function NodePoolConfigCard({
  nodePool,
  onChange,
  vmFlavors,
}: NodePoolConfigCardProps) {
  const [open, toggleOpen] = useToggler(true)
  const classes = useStyles({ open })

  const handleVmChange = (fieldId, value) => {
    onChange(fieldId, value)
  }

  const handleAutoscaling = (enableAutoscaling) => {
    onChange('autoscaling', enableAutoscaling ? { maxNodes: undefined } : undefined)
  }

  const handleMaxNodesChange = (value) => {
    onChange('autoscaling', { maxNodes: value })
  }

  return (
    <Accordion
      id={nodePool?.id}
      title={<Text variant="subtitle2">{`#${nodePool?.id} Node Pool`}</Text>}
      className={classes.nodePoolContainer}
      open={open}
      onClick={() => toggleOpen()}
      icon="chevron-up"
    >
      <div className={classes.content}>
        <div className={classes.borderBottom}>
          <VirtualMachinesTable
            data={vmFlavors}
            selectedVmFlavor={nodePool?.vmFlavor}
            selectedVmQuantity={nodePool?.quantity}
            onChange={handleVmChange}
          />
        </div>
        <div className={clsx(classes.autoscalingFields, classes.borderBottom)}>
          <ToggleSwitchField
            id="enableAutoscaling"
            label="Automatically scale this Node Pool"
            onChange={handleAutoscaling}
          />
          {nodePool.autoscaling !== undefined && (
            <div className={classes.quantitySelector}>
              <Text variant="body2">Max Nodes</Text>
              <QuantitySelector
                id="maxNodes"
                value={nodePool.autoscaling.maxNodes || 0}
                onChange={handleMaxNodesChange}
              />
            </div>
          )}
        </div>
        <div className={classes.dropdownFields}>
          <DropdownField
            id="storageClass"
            label="Storage Class"
            DropdownComponent={AsyncDropdown}
            items={storageClassOptions}
            value={nodePool.storageClass}
            onChange={(value) => onChange('storageClass', value)}
            selectFirst
            required
          />
          <DropdownField
            id="network"
            label="Network / Subnet"
            DropdownComponent={AsyncDropdown}
            items={networkOptions}
            value={nodePool.network}
            onChange={(value) => onChange('network', value)}
            selectFirst
            required
          />
        </div>
      </div>
    </Accordion>
  )
}

const useStyles = makeStyles<Theme, { open: boolean }>((theme) => ({
  nodePoolContainer: {
    display: 'grid',
    border: `1px solid ${theme.components.card.border}`,
    borderRadius: '4px',
    marginBottom: theme.spacing(2),
    '& .accordionTopBar': {
      height: '48px',
    },
    '& .toggleIcon': {
      transform: ({ open }) => (open ? 'rotate(0deg)' : 'rotate(180deg)'),
    },
    '& .accordionContent': {
      padding: theme.spacing(0, 2),
      overflow: ({ open }) => (open ? 'visible' : 'hidden'),
      transition: 'max-height 0.3s ease',
    },
  },
  borderBottom: {
    paddingBottom: theme.spacing(3),
    borderBottom: `1px solid ${theme.components.card.border}`,
  },
  content: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridGap: theme.spacing(2),
    padding: theme.spacing(2),
  },
  autoscalingFields: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  quantitySelector: {
    display: 'grid',
    gridTemplateColumns: 'max-content',
    gridGap: theme.spacing(1),
    marginTop: theme.spacing(),
  },
  dropdownFields: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing(3),
    paddingBottom: theme.spacing(),
  },
}))
